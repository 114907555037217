<style lang="scss">
.martkplatz-mobilitaet {
  .head-row-text-normal {
    .text-normal {
      display: inline-block;
      text-transform: none !important;
    }
  }
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }

  .custom-link-v2 {
    font-weight: 500;
    background: $blue-darker;
    color: #fff !important;
    padding: 10px 15px;
    display: inline-block;
    &:hover {
      opacity: 0.7;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
  }
  .bild-box {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .text-center-small {
    @media (max-width: 992px) {
      text-align: center !important;
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    max-width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="martkplatz-mobilitaet content container">
    <h1>Nehmen Sie Fahrt auf!</h1>
    <p class="intro-txt">
      „Mobilität“ aus vielen unterschiedlichen Perspektiven und aufmerksamkeitsstarke Umfelder voll mit Storys, Trends und Servicegeschichten: Platzieren Sie Ihre Anzeige im reichweitenstärksten Auto- &amp; Motor-Markt im Süden Österreichs - jeden Mittwoch, Freitag (Hauptmarkt) und Sonntag im Mobilitäts-Teil der Kleinen Zeitung.
    </p>
    <p>Von der <strong>klassischen Auto-Anzeige</strong> bis hin zu innovativen <strong>Content-Lösungen:</strong> Wir bieten Ihnen die besten Möglichkeiten für Ihren Kommunikationsbedarf.</p>

    <div class="margin-t-s"><h2>Ihre Werbelösungen für Mobilität</h2></div>

    <TabGroup>
      <Tab tabid="moblitaet-tab1" :title="'Raum- und Zeilenanzeigen'" active>
        <div class="row margin-tb-s">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h2>Raum- und Zeilenanzeigen</h2>
            <p>Jeden Mittwoch, Freitag (Hauptmarkt) im Mobilitäts-Teil der Kleinen Zeitung.</p>
            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss: </strong><br />
              Dienstag, 10 Uhr / Dienstag, 11 Uhr für Mittwoch<br />
              Donnerstag, 10 Uhr / Donnerstag, 11 Uhr für Freitag
            </p>
            <p>
              <strong>Wiederholungsschaltung Raumanzeigen</strong><br />
              50 % auf den Normaltarif bei Schaltung des gleichen Sujets wie bei Erstschaltung innerhalb von 14 Tagen.
            </p>
            <p>
              <strong>Wiederholungsschaltung Zeilenanzeigen</strong><br />
              50 % auf den Normaltarif bei Schaltung des gleichen Sujets wie bei Erstschaltung innerhalb von 7 Tagen nur innerhalb des Hauptmarktes (Freitag) und Zweitmarktes (Mittwoch) möglich.
            </p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//marktplaetze/mobilitaet-bsp-seiten.png" alt="Mobilität" class="img-fluid" />
          </div>
        </div>

        <!-- 
        <div class="row">
          <div class="col-lg-12 justify-content-center align-self-center order-lg-2">
            <div class="tabelle-box tabelle-width-50">
              <div class="headline">
                <strong>Millimeterpreis 4C</strong>
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MO - FR</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">20,68</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">14,83</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">9,07</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA - SO</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">21,88</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">15,48</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">9,47</div>
              </div>
            </div>
          </div>
        </div>
        -->
        <div class="text-center">
          <CollapsibleButton link="mobilitaet-raum-zeilenanzeigen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
        </div>
        <Collapsible link="mobilitaet-raum-zeilenanzeigen-tabellen">
          <h2>Raumanzeigen</h2>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Millimeterpreise </strong>
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder FR</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">20,99</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">15,05</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">9,21</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3"></div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/8 Seite</strong>
                  Fixformat: 47 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder FR</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">2.833,65</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">2.031,75</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">1.243,35</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/4 Seite</strong>
                  Fixformat: 98 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder FR</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5.667,30</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">4.063,50</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">2.486,70</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/2 Seite</strong>
                  Fixformat: 200 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder FR</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">11.334,60</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">8.127,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">4.973,40</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/1 Seite</strong>
                  Fixformat: 200 x 275 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder FR</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">23.089,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">16,555,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">10.131,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x135" class="img-fluid " />
            </div>
          </div>

          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Rubrikenvorspann</strong>
                  Format: 200 x 110 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">2.050,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">1.550,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">950,00</div>
                </div>
                <div class="info-txt-tabelle">Erscheinung nur mittwochs möglich.<br />Pro Mittwochausgabe nur ein Rubrikenvorspann verfügbar.<br />PR-Produktionskosten im Tarif enthalten.</div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x110.svg" alt="200x110" class="img-fluid " />
            </div>
          </div>
          <div class="margin-tb-m"></div>
          <h2>Zeilenanzeigen</h2>

          <div class="row">
            <div class="col-lg-8 justify-content-center align-self-center">
              <div class="tabelle-box alignItems">
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">1. Zeile</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">16,79</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">10,54</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">8,14</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Zusatzzeile</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">12,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">7,67</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">5,72</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Logo 4c</span> je Zeile</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">16,73</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">13,21</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">9,68</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Foto</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">66,21</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">40,33</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">37,15</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Icon</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">3,25</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">3,25</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">3,25</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Online-Code</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">5,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">5,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 justify-content-center align-self-center">
              <img src="@/assets/img/img-content//branchenloesungen/bsp-mobilitaet.jpg" alt="Bsp. Mobilität" class="img-fluid" />
              <div class="text-center">
                <small>UMSETZUNGSBEISPIEL</small>
              </div>
            </div>
          </div>
          <div class="info-txt-preis">
            Sonntags keine Zeilenanzeigen möglich!<br />
            Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer.
          </div>
        </Collapsible>
      </Tab>

      <Tab tabid="moblitaet-tab4" :title="'willhaben.at'">
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <p>Profitieren Sie von der optimalen Kombination „Kleine Zeitung“ und „willhaben“. Mit der reichweitenstärksten Tageszeitung im Süden Österreichs und dem größten digitalen Marktplatz des Landes nutzen Sie das erfolgreichste Kommunikationsnetzwerk Österreichs.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/klz-willhaben.jpg" alt="KLZ + Willhaben" class="img-fluid img-s-60 img-center" />
            </div>
          </div>
        </div>
        <h3>Ihre Werbemöglichkeiten als Add-On zur Kleinen Zeitung.</h3>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <ul class="content-ul">
              <li>Content-Ad 300 x 250 px</li>
              <li>run over vertical</li>
              <li>Anzeigendetailansicht</li>
              <li>C2C-Bereich</li>
              <li>Laufzeit 4 Wochen</li>
            </ul>

            <div class="tabelle-box alignItems">
              <div class="row head-row head-row-text-normal">
                <div class="col-lg-4 col-6 text-align-left hide-small"></div>
                <div class="col-lg-4 col-6 text-align-right text-left-small"><span class="text-normal">Als</span></div>
                <div class="col-lg-4 col-6 text-align-right">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Small</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">10.000</div>
                <div class="col-lg-4 col-6 text-align-right">140,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Medium</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">20.000</div>
                <div class="col-lg-4 col-6 text-align-right">260,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Large</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">30.000</div>
                <div class="col-lg-4 col-6 text-align-right">360,00</div>
              </div>
              <div class="info-txt-tabelle">Anforderungen ans Werbemittel: max. 150kb. Gängige Bildformate (jpg, png, gif etc.) oder HTML Werbemittel sind möglich (hier sind clicktags nach iab Standard einzubauen)</div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/mobilitaet-willhaben.jpg" alt="Moblität Laptop Willhaben img-s-75 img-center" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer. Keine Rabatte möglich.</div>
      </Tab>
    </TabGroup>
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/branchenloesungen/Kleine-Zeitung-Mobilitaet-Vorteilspakete-2022.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Mobilität-Vorteilspakete-Zeilenanzeigen
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>1.483 kb // PDF</span>
          </div>
        </div>
      </a>
      <a href="../../pdf/branchenloesungen/Kleine-Zeitung-Mobilitaet-Regional-Steiermark-2022.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Mobilität Regional Steiermark
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>163 kb // PDF</span>
          </div>
        </div>
      </a>
      <a href="../../pdf/branchenloesungen/Kleine-Zeitung-Mobilitaet-Regionale-Autohändler-Rabattaktion-2022.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Rabattaktion regionaler Autohandel
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>138 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  components: {
    //Modal,
    TabGroup,
    Tab,
    CollapsibleButton,
    Collapsible,
  },
});
</script>
